define('requireHelper', function() {

  var RequireHelper = function() {

    var common = 'common/';
    var commonScripts = common + 'scripts/';
    var extension = '.js';

    var self = this;

    self.getUpdatedPath = function(script, manifest) {
      var commonPath = self.resolveLocalPath(commonScripts + script + extension);
      var commonScriptPath = manifest[commonPath];

      if (commonScriptPath) {
        commonScriptPath = commonScriptPath.split(extension)[0];
      }

      return commonScriptPath || script;
    };

    self.resolveLocalPath = function(path) {
      var parts = path.split('/');
      var stack = [];

      for (var i = 0, len = parts.length; i < len; i++) {

        if (parts[i] === '..') {
          stack.pop();
        } else if (parts[i] !== '.') {
          stack.push(parts[i]);
        }
      }
      return stack.join('/');
    };

  };

  return RequireHelper;
});
